const redirect = ["/lookbook", "/lookbookpl", "/productsru", "/pl-01", "/startsurvey"];

export default defineNuxtRouteMiddleware(async (to) => {
  // if (to.path === "/claim_your_gift_line") {
  //   return externalRedirect("https://swag42.com/get_design");
  // }
  if (redirect.includes(to.path)) {
    return externalRedirect("https://swag42.com");
  }
});
