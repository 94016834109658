export default defineNuxtPlugin(() => {
  window.Trengo = window.Trengo || {};
  window.Trengo.key = "xY6tiY3U8b66yC4nHiHM";
  window.addEventListener("load", () => {
    setTimeout(() => {
      (function (d, script) {
        script = d.createElement("script");
        script.type = "text/javascript";
        script.defer = true;
        script.src = "https://static.widget.trengo.eu/embed.js";
        d.getElementsByTagName("head")[0].appendChild(script);
      })(document);
    }, 10000);
  });
});
