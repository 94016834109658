
// @ts-nocheck


export const localeCodes =  [
  "en",
  "pl"
]

export const localeLoaders = {
  "en": [],
  "pl": []
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "iso": "en-US"
    },
    {
      "code": "pl",
      "iso": "pl-PL"
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": true,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "privacy-policy": {
      "en": "/privacy-policy",
      "pl": "/polityka-prywatnosci"
    },
    "terms-of-use": {
      "en": "/terms-of-use",
      "pl": "/regulamin"
    },
    "terms-of-sale": {
      "en": "/terms-of-sale",
      "pl": "/warunki-sprzedazy"
    },
    "contact-us": {
      "en": "/contact-us",
      "pl": "/kontakt"
    },
    "about-us": {
      "en": "/about-us",
      "pl": "/o-nas"
    },
    "careers": {
      "en": "/careers",
      "pl": "/kariera"
    },
    "platform": {
      "en": "/platform",
      "pl": "/platforma"
    },
    "content-hub/index": {
      "en": "/content-hub",
      "pl": "/content-hub"
    },
    "content-hub/[url]": {
      "en": "/content-hub/[url]",
      "pl": "/content-hub/[url]"
    },
    "catalog/index": {
      "en": "/catalog",
      "pl": "/katalog"
    },
    "catalog/[category]/index": {
      "en": "/catalog/[category]",
      "pl": "/katalog/[category]"
    },
    "catalog/[category]/[item]/index": {
      "en": "/catalog/[category]/[item]",
      "pl": "/katalog/[category]/[item]"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "iso": "en-US",
    "files": []
  },
  {
    "code": "pl",
    "iso": "pl-PL",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
