import validate from "/vercel/path1/node_modules/nuxt/dist/pages/runtime/validate.js";
import oldpages_45global from "/vercel/path1/middleware/oldpages.global.js";
import router_45global from "/vercel/path1/middleware/router.global.ts";
import manifest_45route_45rule from "/vercel/path1/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  oldpages_45global,
  router_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}