export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  useHead({
    script: [
      {
        src: `https://www.google.com/recaptcha/api.js?render=${config.public.recaptchaSiteKey}`,
        defer: true,
        onload: () => {
          grecaptcha.ready(function () {
            grecaptcha.execute(config.public.recaptchaSiteKey, { action: "homepage" }).then(function (token) {
              useCommonStore().recaptchaToken = token;
            });
          });
        },
      },
    ],
    link: [
      {
        rel: "preconnect",
        href: "https://www.google.com",
      },
      {
        rel: "preconnect",
        href: "https://www.gstatic.com",
        crossorigin: true,
      },
    ],
  });
});
