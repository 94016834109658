// function checkIsWebPSupported() {
//   if (typeof document === "undefined") return false;
//   const elem = document.createElement("canvas");
//   if (!!(elem.getContext && elem.getContext("2d"))) return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
//   return false;
// }
// const isWebPSupported = checkIsWebPSupported();

export default defineNuxtPlugin(() => {
  return {
    provide: {
      getImageUrl: (id, params, webp = true) => {
        let localParams = {};
        // if (isWebPSupported && webp) localParams.format = "webp";
        if (params) {
          localParams = { ...localParams, ...params };
        }
        const config = useRuntimeConfig();
        return `${config.public.directusUrl}/assets/${id}?${Object.entries(localParams)
          .map(([key, value]) => `${key}=${value}`)
          .join("&")}`;
      },
      getImageByDirectusIdWithoutCORS: async (id, params, webp = true) => {
        let localParams = {};
        // if (isWebPSupported && webp) localParams.format = "webp";
        if (params) {
          localParams = { ...localParams, ...params };
        }
        return await $fetch(
          `/api/assets/${id}?${Object.entries(localParams)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")}`,
        );
      },
      localeDictionary() {
        const { locale } = useNuxtApp().$i18n;
        const config = useRuntimeConfig();
        return computed(() => (config.public.localesDictionary?.[locale.value] ? config.public.localesDictionary[locale.value] : config.public.localesDictionary.en));
      },
      appScrollTo(top = 0, behavior = "smooth") {
        document.getElementById("app")?.scrollTo({ top, behavior });
      },
    },
  };
});
