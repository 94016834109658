import { default as icons_45pageqGK3SG69QAMeta } from "/vercel/path1/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue?macro=true";
import { default as _404JTFtqUMwdDMeta } from "/vercel/path1/pages/404.vue?macro=true";
import { default as about_45us2qOb8PnoflMeta } from "/vercel/path1/pages/about-us.vue?macro=true";
import { default as careersgGtAg9X069Meta } from "/vercel/path1/pages/careers.vue?macro=true";
import { default as indexuvajHvyvvyMeta } from "/vercel/path1/pages/case_studies/[case]/index.vue?macro=true";
import { default as index6j6aDTwGuYMeta } from "/vercel/path1/pages/case_studies/index.vue?macro=true";
import { default as indexxjqo6W3UpJMeta } from "/vercel/path1/pages/catalog/[category]/[item]/index.vue?macro=true";
import { default as indexmqIgTddqzkMeta } from "/vercel/path1/pages/catalog/[category]/index.vue?macro=true";
import { default as indexEv6TBYFCVFMeta } from "/vercel/path1/pages/catalog/index.vue?macro=true";
import { default as searchnKpJW5dw37Meta } from "/vercel/path1/pages/catalog/search.vue?macro=true";
import { default as contact_45us0tgBB1qDYqMeta } from "/vercel/path1/pages/contact-us.vue?macro=true";
import { default as _91url_93c3WuJanfmZMeta } from "/vercel/path1/pages/content-hub/[url].vue?macro=true";
import { default as indexvJgaZhrKHBMeta } from "/vercel/path1/pages/content-hub/index.vue?macro=true";
import { default as indexzhmolsBfabMeta } from "/vercel/path1/pages/index.vue?macro=true";
import { default as platformwmr9j0M6RwMeta } from "/vercel/path1/pages/platform.vue?macro=true";
import { default as polityka_45prywatnoscirObO4ZQwDIMeta } from "/vercel/path1/pages/polityka-prywatnosci.vue?macro=true";
import { default as privacy_45policygAbxXqOfHnMeta } from "/vercel/path1/pages/privacy-policy.vue?macro=true";
import { default as _91id_932suuDHbdoEMeta } from "/vercel/path1/pages/products/[id].vue?macro=true";
import { default as questions8BOLbCXu0JMeta } from "/vercel/path1/pages/questions.vue?macro=true";
import { default as terms_45of_45salesJhj3UBWqaMeta } from "/vercel/path1/pages/terms-of-sale.vue?macro=true";
import { default as terms_45of_45usey0jAQiuNHrMeta } from "/vercel/path1/pages/terms-of-use.vue?macro=true";
import { default as webinar_45specKztVqktXxCMeta } from "/vercel/path1/pages/webinar-spec.vue?macro=true";
import { default as component_45stubzwvYzw30dnMeta } from "/vercel/path1/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubzwvYzw30dn } from "/vercel/path1/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "icons-page",
    path: "/_icons",
    meta: {"layout":"svg-sprite"},
    component: () => import("/vercel/path1/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue").then(m => m.default || m)
  },
  {
    name: "404___en",
    path: "/404",
    component: () => import("/vercel/path1/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "404___pl",
    path: "/pl/404",
    component: () => import("/vercel/path1/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "about-us___en",
    path: "/about-us",
    component: () => import("/vercel/path1/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "about-us___pl",
    path: "/pl/o-nas",
    component: () => import("/vercel/path1/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "careers___en",
    path: "/careers",
    component: () => import("/vercel/path1/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: "careers___pl",
    path: "/pl/kariera",
    component: () => import("/vercel/path1/pages/careers.vue").then(m => m.default || m)
  },
  {
    name: "case_studies-case___en",
    path: "/case_studies/:case()",
    component: () => import("/vercel/path1/pages/case_studies/[case]/index.vue").then(m => m.default || m)
  },
  {
    name: "case_studies-case___pl",
    path: "/pl/case_studies/:case()",
    component: () => import("/vercel/path1/pages/case_studies/[case]/index.vue").then(m => m.default || m)
  },
  {
    name: "case_studies___en",
    path: "/case_studies",
    component: () => import("/vercel/path1/pages/case_studies/index.vue").then(m => m.default || m)
  },
  {
    name: "case_studies___pl",
    path: "/pl/case_studies",
    component: () => import("/vercel/path1/pages/case_studies/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-category-item___en",
    path: "/catalog/:category()/:item()",
    component: () => import("/vercel/path1/pages/catalog/[category]/[item]/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-category-item___pl",
    path: "/pl/katalog/:category()/:item()",
    component: () => import("/vercel/path1/pages/catalog/[category]/[item]/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-category___en",
    path: "/catalog/:category()",
    component: () => import("/vercel/path1/pages/catalog/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-category___pl",
    path: "/pl/katalog/:category()",
    component: () => import("/vercel/path1/pages/catalog/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog___en",
    path: "/catalog",
    component: () => import("/vercel/path1/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog___pl",
    path: "/pl/katalog",
    component: () => import("/vercel/path1/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: "catalog-search___en",
    path: "/catalog/search",
    component: () => import("/vercel/path1/pages/catalog/search.vue").then(m => m.default || m)
  },
  {
    name: "catalog-search___pl",
    path: "/pl/catalog/search",
    component: () => import("/vercel/path1/pages/catalog/search.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___en",
    path: "/contact-us",
    component: () => import("/vercel/path1/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "contact-us___pl",
    path: "/pl/kontakt",
    component: () => import("/vercel/path1/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "content-hub-url___en",
    path: "/content-hub/:url()",
    component: () => import("/vercel/path1/pages/content-hub/[url].vue").then(m => m.default || m)
  },
  {
    name: "content-hub-url___pl",
    path: "/pl/content-hub/:url()",
    component: () => import("/vercel/path1/pages/content-hub/[url].vue").then(m => m.default || m)
  },
  {
    name: "content-hub___en",
    path: "/content-hub",
    component: () => import("/vercel/path1/pages/content-hub/index.vue").then(m => m.default || m)
  },
  {
    name: "content-hub___pl",
    path: "/pl/content-hub",
    component: () => import("/vercel/path1/pages/content-hub/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/vercel/path1/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pl",
    path: "/pl",
    component: () => import("/vercel/path1/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "platform___en",
    path: "/platform",
    component: () => import("/vercel/path1/pages/platform.vue").then(m => m.default || m)
  },
  {
    name: "platform___pl",
    path: "/pl/platforma",
    component: () => import("/vercel/path1/pages/platform.vue").then(m => m.default || m)
  },
  {
    name: "polityka-prywatnosci___en",
    path: "/polityka-prywatnosci",
    component: () => import("/vercel/path1/pages/polityka-prywatnosci.vue").then(m => m.default || m)
  },
  {
    name: "polityka-prywatnosci___pl",
    path: "/pl/polityka-prywatnosci",
    component: () => import("/vercel/path1/pages/polityka-prywatnosci.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    component: () => import("/vercel/path1/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___pl",
    path: "/pl/polityka-prywatnosci",
    component: () => import("/vercel/path1/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "products-id___en",
    path: "/products/:id()",
    component: () => import("/vercel/path1/pages/products/[id].vue").then(m => m.default || m)
  },
  {
    name: "products-id___pl",
    path: "/pl/products/:id()",
    component: () => import("/vercel/path1/pages/products/[id].vue").then(m => m.default || m)
  },
  {
    name: "questions___en",
    path: "/questions",
    component: () => import("/vercel/path1/pages/questions.vue").then(m => m.default || m)
  },
  {
    name: "questions___pl",
    path: "/pl/questions",
    component: () => import("/vercel/path1/pages/questions.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-sale___en",
    path: "/terms-of-sale",
    component: () => import("/vercel/path1/pages/terms-of-sale.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-sale___pl",
    path: "/pl/warunki-sprzedazy",
    component: () => import("/vercel/path1/pages/terms-of-sale.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use___en",
    path: "/terms-of-use",
    component: () => import("/vercel/path1/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use___pl",
    path: "/pl/regulamin",
    component: () => import("/vercel/path1/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "webinar-spec___en",
    path: "/webinar-spec",
    component: () => import("/vercel/path1/pages/webinar-spec.vue").then(m => m.default || m)
  },
  {
    name: "webinar-spec___pl",
    path: "/pl/webinar-spec",
    component: () => import("/vercel/path1/pages/webinar-spec.vue").then(m => m.default || m)
  },
  {
    name: component_45stubzwvYzw30dnMeta?.name,
    path: "/pl/solutions",
    component: component_45stubzwvYzw30dn
  },
  {
    name: component_45stubzwvYzw30dnMeta?.name,
    path: "/solutions",
    component: component_45stubzwvYzw30dn
  },
  {
    name: component_45stubzwvYzw30dnMeta?.name,
    path: "/pl/Solutions",
    component: component_45stubzwvYzw30dn
  },
  {
    name: component_45stubzwvYzw30dnMeta?.name,
    path: "/Solutions",
    component: component_45stubzwvYzw30dn
  },
  {
    name: component_45stubzwvYzw30dnMeta?.name,
    path: "/pl/services",
    component: component_45stubzwvYzw30dn
  },
  {
    name: component_45stubzwvYzw30dnMeta?.name,
    path: "/services",
    component: component_45stubzwvYzw30dn
  },
  {
    name: component_45stubzwvYzw30dnMeta?.name,
    path: "/pl/Services",
    component: component_45stubzwvYzw30dn
  },
  {
    name: component_45stubzwvYzw30dnMeta?.name,
    path: "/Services",
    component: component_45stubzwvYzw30dn
  }
]