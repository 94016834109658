import routerOptions0 from "/vercel/path1/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/vercel/path1/app/router.options.js";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}