
import * as ipxRuntime$GMtNSm0HW0 from '/vercel/path1/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as directusRuntime$phg0GLX1pL from '/vercel/path1/node_modules/@nuxt/image/dist/runtime/providers/directus'
import * as vercelRuntime$GIRiUPfqBR from '/vercel/path1/node_modules/@nuxt/image/dist/runtime/providers/vercel'

export const imageOptions = {
  "screens": {
    "xs": 390,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536,
    "desktop": 1440,
    "3xl": 1921,
    "4k": 2560
  },
  "presets": {},
  "provider": "vercel",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$GMtNSm0HW0, defaults: {"maxAge":2592000} },
  ['directus']: { provider: directusRuntime$phg0GLX1pL, defaults: {"baseURL":"https://cms.swag42.com/assets/"} },
  ['vercel']: { provider: vercelRuntime$GIRiUPfqBR, defaults: {} }
}
        