import revive_payload_client_4sVQNw7RlN from "/vercel/path1/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path1/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path1/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/vercel/path1/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/vercel/path1/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path1/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path1/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path1/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path1/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path1/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_W86eSGFqyZ from "/vercel/path1/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import plugin_client_UYiXMU8ZyN from "/vercel/path1/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import nuxt_plugin_6wEQMY3tee from "/vercel/path1/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import sentry_client_VdOeuw25Mb from "/vercel/path1/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path1/.nuxt/sentry-client-config.mjs";
import element_plus_teleports_plugin_h4Dmekbj62 from "/vercel/path1/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/vercel/path1/.nuxt/element-plus-injection.plugin.mjs";
import i18n_yfWm7jX06p from "/vercel/path1/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import alwan_client_gUQq8DYTGl from "/vercel/path1/plugins/alwan.client.js";
import helpers_ipnH1tCNhR from "/vercel/path1/plugins/helpers.js";
import markdownit_L5kJV8yeqz from "/vercel/path1/plugins/markdownit.js";
import recaptcha_client_ePVGy12Odm from "/vercel/path1/plugins/recaptcha.client.js";
import start_url_client_pXUoQjp8KY from "/vercel/path1/plugins/start-url.client.js";
import trengo_client_ZYm3UVc1r5 from "/vercel/path1/plugins/trengo.client.js";
import vue_gtm_client_Cm24Dsc5BN from "/vercel/path1/plugins/vue-gtm.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_W86eSGFqyZ,
  plugin_client_UYiXMU8ZyN,
  nuxt_plugin_6wEQMY3tee,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  i18n_yfWm7jX06p,
  alwan_client_gUQq8DYTGl,
  helpers_ipnH1tCNhR,
  markdownit_L5kJV8yeqz,
  recaptcha_client_ePVGy12Odm,
  start_url_client_pXUoQjp8KY,
  trengo_client_ZYm3UVc1r5,
  vue_gtm_client_Cm24Dsc5BN
]