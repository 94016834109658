import { defineNuxtPlugin } from "#app";

import mdit from "markdown-it";
import markdownItClass from "@toycode/markdown-it-class";
// import markdowStyle from "markdown-it-style";
// import sub from "markdown-it-sub";
// import sup from "markdown-it-sup";
// import fn from "markdown-it-footnote";
// import emo from "markdown-it-emoji";
// import def from "markdown-it-deflist";
// import ins from "markdown-it-ins";
// import container from "markdown-it-container";

const markdownit = new mdit({ breaks: true }).use(markdownItClass, {
  p: "leading-5 lg:leading-6",
  ul: "list-disc leading-5 lg:leading-8 pl-6 lg:pl-7.5",
  strong: "leading-9 font-black py-2 lg:py-0",
});

markdownit.linkify.set({ fuzzyEmail: false });

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide("mdit", markdownit);
});
