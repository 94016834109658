import { defineStore } from "pinia";

// import { useGtag } from "vue-gtag-next";
// const { event } = useGtag();
// ev : form_closed, cat :negative, label: buttons
const track = (ev, cat, label) => {
  // event(ev, {
  //   event_category: cat,
  //   event_label: label,
  // });
};

export const useCommonStore = defineStore("common-store", {
  state: () => ({
    // isShowFormBookCall: false,
    // nyBoxModal: {
    //   isShowNYBoxesModal: false,
    //   type: "",
    // },
    isShowScheduleModal: false,
    recaptchaToken: null,
  }),
  actions: {
    toggle() {
      if (!this.isShowScheduleModal) {
        this.sendEvent("form opened", "user activity", "button");
      }
      this.isShowScheduleModal = !this.isShowScheduleModal;
    },
    sendEvent(ev, cat, label) {
      track(ev, cat, label);
    },
  },
});
